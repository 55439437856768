import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import FormsyInputField from '../elements/FormsyInputField';
import FormsyDatePicker from '../../elements/FormsyDatePicker';
import FormsySelect from '../../elements/FormsySelect';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import LeadRemarkHistory from '../lead/component/LeadRemarkHistory';
import Modal from '../../elements/Modal';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../../services/refinance/executeGraphQLQuery';
import { UCRF_SAVE_CUSTOMER_BASIC_DETAILS } from '../../../services/customer.gql';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { getUCRFFormFillStatus, getUCRFLeadDetail, getLeadRemarkUCRF } from '../../../store/action/allAction';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE, NAME_WITH_LATIN_CHARACTER_REGEX } from '../../../config/constants';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import Loader from '../../elements/Loader';
import DocumentGallery from '../DocsUpload/DocumentGallery';
import { BASIC_DETAILS, CONTRACT_SIGNED, LOST_STATUS, SENT_TO_FINANCIER } from '../../../config/refinance/constant';
import { UCRF_GET_ALL_DEALER_LIST } from '../../../services/refinance/refinance-config.gql';
const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  let labeltxtClass = props.selectProps && props.selectProps.placeholder && props.selectProps.placeholder.length >= 25 ? 'label-txt-select' : '';
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused} className={labeltxtClass}>
        {props.selectProps.placeholder}
        {props.selectProps.isRequired ? <span> *</span> : null}
      </Placeholder>
      {React.Children.map(children, child =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const RefinanceBasicDetailsForm = (props) => {
  const [basicDetails, setBasicDetails] = useState({});
  const [orgbasicDetails, setOrgBasicDetails] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false);
  const [dealerList, setDealerList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isSaveDisable, setIsSaveDisabled] = useState(true)
  const [isRemarkDisable, setIsRemarkDisabled] = useState(true)
  const [isNextEnable, setIsNextEnabled] = useState(false)
  const { master_data_ucrf, remarksHistory, leadDetail } = useSelector(({ lead, masterdataucrf }) => ({
    master_data_ucrf: masterdataucrf?.master_data_ucrf || {},
    lead,
    remarksHistory: lead.remarksHistory || [],
    leadDetail: lead?.ucrfLeadDetail || {},
  }));
  const client = useApolloClient();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let { lead_id } = useParams();

  const user_information = useSelector((state) => state.user.user_information);
  const UserInfo = JSON.parse(user_information);
  let user_id = (UserInfo && UserInfo.id) ? UserInfo.id : 0;

  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || []

  useEffect(() => {
    if (leadDetail && leadDetail?.basic_details) {
      let basicDetails = { ...leadDetail.basic_details };
      basicDetails["date_of_birth"] = basicDetails?.["date_of_birth"] && basicDetails["date_of_birth"] || "";
      setBasicDetails(basicDetails);
      setOrgBasicDetails(basicDetails)
      checkSaveEnable(leadDetail)
    }
  }, [leadDetail]);

  useEffect(() => {
    if (leadDetail?.basic_details?.user_id) {
      setLoading(true)
      if (leadDetail?.basic_details?.dealer_id) {
        getDealerList(leadDetail.basic_details.user_id, leadDetail?.basic_details?.dealer_id).then((res) => {
          setLoading(false)
        })
      } else {
        setLoading(false)
      }
    }
    dispatch(getLeadRemarkUCRF({ lead_id: leadDetail.lead_id, source: 'WEB' }, client));
  }, [leadDetail]);// eslint-disable-line react-hooks/exhaustive-deps


  let ALL_OPTIONS = useMemo(() => {
    let options = master_data_ucrf ? { ...master_data_ucrf } : {};
    options["dealer_id"] = dealerList || [];
    return options;
  }, [master_data_ucrf, dealerList]);

  const loadOptions = async (inputValue) => {
    return new Promise(async (resolve, reject) => {
      if (inputValue.trim().length > 2 && basicDetails?.user_id) {
        let dealers = await getDealerList(basicDetails.user_id, inputValue)
        resolve(dealers);
      }
    });
  };

  const getDealerList = async (user_id = 0, search_text) => {
    let dealers = []
    search_text = search_text ? search_text.toString() : ""
    await executeGraphQLQuery(UCRF_GET_ALL_DEALER_LIST(1, user_id, search_text), client)
      .then((result) => {
        if (result?.data?.getAllDealerList) {
          dealers = result.data.getAllDealerList || []
          dealers = dealers.map((ob) => ({
            id: ob.value,
            label: ob.label + "(" + ob.dealerCode + ")",
            value: ob.value
          }))
          setDealerList(dealers)
        }
      })
      .catch((errors) => {
        toast.error("Somethig went wrong ,please try again");
      });
    return dealers
  };

  const handleInputChange = event => {
    if (event.target.name) {
      setBasicDetails(currentValue => ({
        ...currentValue,
        [event.target.name]: event.target.value
      }))
    }
  }

  const handlePatternFormatChange = (data, field_name) => {
    if (data.value !== undefined && field_name) {
      setBasicDetails(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }

  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
      setBasicDetails(currentValue => ({
        ...currentValue,
        [field_name]: date
      }))
    }
  }

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      setBasicDetails(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }

  const handleSubmit = () => {
    let lead_id = leadDetail?.lead_id || "";
    let reqBody = {
      source_type: basicDetails.source_type || "",
      customer_name: basicDetails.customer_name || "",
      customer_mobile_no: basicDetails.customer_mobile_no || "",
      date_of_birth: basicDetails.date_of_birth || "",
      vehicle_plate_number: basicDetails.vehicle_plate_number || "",
      customer_govt_id: basicDetails.customer_govt_id || "",
      agent_name: basicDetails.agent_name || "",
      agent_mobile_no: basicDetails.agent_mobile_no || "",
      loan_purpose: basicDetails.loan_purpose || "",
      govt_id_proof: basicDetails.govt_id_proof || "",
      source_description: basicDetails.source_description || "",
      dealer_id: basicDetails.dealer_id || 0,
      updated_by: user_id || 0
    }
    let mutation = UCRF_SAVE_CUSTOMER_BASIC_DETAILS,
      variables = {
        basic_details: reqBody,
        tab_id: 1,
        sub_tab_id: 0,
        lead_id,
        user_id: basicDetails.user_id,
        role_id: basicDetails.role_id
      };
    setLoading(true)
    executeGraphQLMutation(mutation, variables, client).then(resp => {
      let response = resp?.data?.saveLeadDetails || null;
      if(response?.is_duplicate){
        throw new Error(response?.message || "Something went wrong");
      } else if (response && !response.lead_id) {
        throw new Error(response.error || "Something went wrong");
      }
      toast.success("Success");
      dispatch(getUCRFLeadDetail(lead_id, client))
      dispatch(getUCRFFormFillStatus({ lead_id }, client))
      setLoading(false)
      navigateToNext();
    })
      .catch(err => {
        setLoading(false)
        toast.error(err.message);
      })
  }

  const navigateToNext = () => {
    navigate(`/refinance/lead-detail/select-financier/${lead_id}`);
  }

  const showModalViewTimeLine = () => {
    setViewTimeLine(true);
    setShowremarkform(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };

  const nameWithLatinCharacterValidation = (rule, value) => {
    return NAME_WITH_LATIN_CHARACTER_REGEX.test(value);
  }

  const checkSaveEnable = (leadDetail) => {
    let markAsFreezed = leadDetail?.customer_timeline_status_ids?.length && leadDetail.customer_timeline_status_ids.findIndex(e => e == SENT_TO_FINANCIER || e == CONTRACT_SIGNED) > -1 ? true : false
    let leadLost = leadDetail?.basic_details?.refinance_status ? leadDetail?.basic_details?.refinance_status === LOST_STATUS.toString() : false;
    let user_page_access = IS_USER_ACCESS_PAGE("refinanceLoanDetail", "ucrf-basic-details")?.is_edit_access
    let user_edit_access = CHECK_ASSIGN_USER(leadDetail.assigned_to)
    let nextEnable = leadDetail?.customer_timeline_status_ids?.length && leadDetail.customer_timeline_status_ids.findIndex(e => e == BASIC_DETAILS) > -1 ? true : false
    if (!user_edit_access && user_page_access && !leadLost && !markAsFreezed) {
      setIsSaveDisabled(false)
      setIsNextEnabled(false)
    } else if (nextEnable) {
      setIsNextEnabled(true)
      setIsSaveDisabled(true)
    } else {
      setIsSaveDisabled(true)
      setIsNextEnabled(false)
    }
    if (!markAsFreezed && !user_edit_access && user_page_access) {
      setIsRemarkDisabled(false)
    }
  }

  const sectionInfo = { section: "Basic Details", sub_section: "Basic Details" }
  return (
    <div className="image-form-outer" >
      {isLoading ? <Loader /> : ''}
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
            <h2>Basic Details</h2>
          </div>
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
            aria-label="lead form field basic"
          >
            <div className="row">
              <fieldset class="form-filed col-md-6 ">
                <FormsyInputField
                  id="customer_name"
                  name="customer_name"
                  type="tb"
                  value={basicDetails?.["customer_name"] || ""}
                  placeholder=" "
                  label={"Customer Name"}
                  onChange={handleInputChange}
                  validations={nameWithLatinCharacterValidation}
                  validationError="Enter Valid Customer Name"
                  readOnly={(orgbasicDetails?.["customer_name"] && isSaveDisable) ? true : false}
                  required={true}
                  showAsterisk={true}
                  isDisabled={isSaveDisable}
                />
              </fieldset>

              <fieldset class="form-filed col-md-6 ">
                <FormsyInputField
                  id="customer_mobile_no"
                  name="customer_mobile_no"
                  type="mobile"
                  value={basicDetails?.["customer_mobile_no"] || ""}
                  placeholder=" "
                  label="Customer Mobile Number"
                  onChange={(data) => handlePatternFormatChange(data, "customer_mobile_no")}
                  format={"+63 ### ### ######"}
                  validations={"isValidPhoneNumber"}
                  validationError="Enter Valid Customer Mobile Number"
                  readOnly={true}
                  required={true}
                  showAsterisk={true}
                  isDisabled={isSaveDisable}
                />
              </fieldset>

              <fieldset class="form-filed col-md-6 ">
                <FormsyInputField
                  id="vehicle_plate_number"
                  name="vehicle_plate_number"
                  type="tb"
                  value={basicDetails?.["vehicle_plate_number"] || ""}
                  placeholder=" "
                  label={"Vehicle Plate Number"}
                  onChange={handleInputChange}
                  validationError="Enter Valid Vehicle Plate Number"
                  readOnly={isSaveDisable}
                  required={true}
                  showAsterisk={true}
                  isDisabled={isSaveDisable}
                />
              </fieldset>
              <fieldset class="form-filed col-md-6 ">
                <FormsyDatePicker
                  name="date_of_birth"
                  selectedDate={(basicDetails && basicDetails['date_of_birth'] && new Date(basicDetails["date_of_birth"])) || ""}
                  placeholder={"Date Of Birth"}
                  maxDate={new Date()}
                  value={(basicDetails && basicDetails["date_of_birth"] && new Date(basicDetails["date_of_birth"])) || ""}
                  onDateSelect={(date) => handleDateChange(date, "date_of_birth")}
                  dateFormat="dd MMM, yyyy"
                  validationError="Enter Valid Date Of Birth"
                  required={false}
                  showAsterisk={true}
                  readOnly={isSaveDisable}
                  isDisabled={isSaveDisable}
                />
              </fieldset>

              <fieldset class="single-select col-md-6">
                <FormsySelect
                  name={"customer_govt_id"}
                  id={"customer_govt_id"}
                  inputProps={{
                    options: ALL_OPTIONS && ALL_OPTIONS["customer_govt_id"],
                    placeholder: "Customer Govt ID",
                    className: "react-select",
                    classNamePrefix: "react-select",
                    value: ALL_OPTIONS && ALL_OPTIONS["customer_govt_id"] && ALL_OPTIONS["customer_govt_id"].filter(({ value }) => value == basicDetails["customer_govt_id"]?.toString()),
                    isDisabled: (orgbasicDetails?.["customer_govt_id"] && isSaveDisable)
                  }}
                  required={true}
                  showAsterisk={true}
                  value={basicDetails && basicDetails["customer_govt_id"]}
                  onChange={(data) => handleSelectChange(data, "customer_govt_id")}
                />
              </fieldset>

              <fieldset class="single-select col-md-6">
                <FormsySelect
                  name={"source_type"}
                  id={"source_type"}
                  inputProps={{
                    options: ALL_OPTIONS && ALL_OPTIONS["source_type"],
                    placeholder: "Source Type",
                    className: "react-select",
                    classNamePrefix: "react-select",
                    value: ALL_OPTIONS && ALL_OPTIONS["source_type"] && ALL_OPTIONS["source_type"].filter(({ value }) => value == basicDetails["source_type"]?.toString()),
                    isDisabled: (orgbasicDetails?.["source_type"] && isSaveDisable)
                  }}
                  required={true}
                  showAsterisk={true}
                  value={basicDetails && basicDetails["source_type"]}
                  onChange={(data) => handleSelectChange(data, "source_type")}
                />
              </fieldset>

              {basicDetails["source_type"] && basicDetails["source_type"] == "dealer" &&
                <fieldset class="single-select col-md-6">
                  <AsyncSelect
                    components={{ ValueContainer: CustomValueContainer }}
                    onChange={(data) => handleSelectChange(data, "dealer_id")}
                    defaultOptions={ALL_OPTIONS && ALL_OPTIONS["dealer_id"]}
                    placeholder="Dealer Name(Type Please...)"
                    label = "Dealer Name"  
                    className="react-select"
                    classNamePrefix="react-select"
                    value={ALL_OPTIONS && ALL_OPTIONS["dealer_id"] && ALL_OPTIONS["dealer_id"].filter(({ value }) => value == basicDetails["dealer_id"]?.toString())}
                    isDisabled={(orgbasicDetails?.["dealer_id"] && isSaveDisable)}
                    loadOptions={e => loadOptions(e)}
                  />
                </fieldset>
              }

              {basicDetails["source_type"] && basicDetails["source_type"] == "agent" &&
                <div>
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                      id="agent_name"
                      name="agent_name"
                      type="tb"
                      value={basicDetails?.["agent_name"] || ""}
                      placeholder=" "
                      label={"Agent Name"}
                      onChange={handleInputChange}
                      validations={nameWithLatinCharacterValidation}
                      validationError="Enter Valid Agent Name"
                      readOnly={(orgbasicDetails?.["agent_name"] && isSaveDisable) ? true : false}
                      required={true}
                      showAsterisk={true}
                    />
                  </fieldset>
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                      id="agent_mobile_no"
                      name="agent_mobile_no"
                      type="mobile"
                      value={basicDetails?.["agent_mobile_no"] || ""}
                      placeholder=" "
                      label="Agent Mobile Number"
                      onChange={(data) => handlePatternFormatChange(data, "agent_mobile_no")}
                      format={"+63 ### ### ######"}
                      validations={"isValidPhoneNumber"}
                      validationError="Enter Valid Agent Mobile Number"
                      readOnly={(orgbasicDetails?.["agent_mobile_no"] && isSaveDisable) ? true : false}
                      required={true}
                      showAsterisk={true}
                    />
                  </fieldset>
                </div>
              }

              {basicDetails["source_type"] && basicDetails["source_type"] == "individual" &&
                <fieldset class="form-filed col-md-6 ">
                  <FormsyInputField
                    id="source_description"
                    name="source_description"
                    type="tb"
                    value={basicDetails?.["source_description"] || ""}
                    placeholder=" "
                    label={"Source Description"}
                    onChange={handleInputChange}
                    validations={nameWithLatinCharacterValidation}
                    validationError="Enter Valid Source Description"
                    readOnly={(orgbasicDetails?.["source_description"] && isSaveDisable) ? true : false}
                    required={true}
                    showAsterisk={true}
                  />
                </fieldset>
              }

            </div>

            <div className="btn-save-remarks">
              {(!allowSubmit || isSaveDisable) ?
                <>{isNextEnable ?
                  <button type="button" className="btn-primary" onClick={() => navigateToNext()}>Next</button>
                  :
                  <span className="span-disabled">
                    Save
                  </span>
                }
                </>
                :
                <button aria-label="save naxt" type="submit" className="btn-primary">
                  Save & Next
                </button>
              }
              <button aria-label="remarks history" type="button" className="btn-line" onClick={showModalViewTimeLine}>Remarks
                <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
              </button>
            </div>
          </Formsy>
        </div>
      </div>
      <div className="view-timeline-popup">
        <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
          <div className="modal-header">
            <h2>Remark History</h2>
          </div>
          <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezedNewFlow}
            sectionInfo={sectionInfo} isRemarkDisable={isRemarkDisable} />
        </Modal>
      </div>
    </div>
  );
}
export default RefinanceBasicDetailsForm;