import React, { Component } from 'react';
import { Tooltip } from 'react-tippy';
import { Tab, Tabs } from 'react-bootstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Loader from '../../elements/Loader';
import { CUSTOMER_PRIMARY_DOC_ID, COBORROWER_PRIMARY_DOC_ID } from '../../../config/constants';
import { LOAN_APPROVAL_PARENT_ID, SOFT_APPROVAL_PARENT_ID } from '../../../config/refinance/constant';
// import LeadHelper from "../LeadHelper";
class UploadDocumentList extends Component {
    constructor(props) {
        super(props);
        this.tipContentRef = React.createRef();
        this.buttonRef = React.createRef();
        this.state = {
            tipOpen: 0,
            UploadedFiles: props.stateleadDocs || [],
            isLoading: false,
            displayTootipIcon: false
        };
        this.toggleTip = this.toggleTip.bind(this);
        this.bodyClick = this.bodyClick.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.UploadedFiles !== nextProps.stateleadDocs) {
            this.setState({ UploadedFiles: nextProps.stateleadDocs });
        }
    }


    componentDidMount() {
        document.addEventListener('mousedown', this.bodyClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.bodyClick);
    }

    toggleTip(id = 0) {
        let { tipOpen } = this.state;
        if (id) this.setState({ tipOpen: (tipOpen === id) ? 0 : id });
    }
    bodyClick(e) {
        this.setState({ tipOpen: 0 });
    }

    setFilter = (doc_id, parent_id, financier_id) => {
        this.props.setFilter({ doc_id: doc_id, file_id: 0, parent_id: parent_id, financier_id: financier_id });
    }

    updateChildDocList = (doc_list, doc_id, customer_type, sec_doc_id) => {
        return doc_list?.map(v => {
            if (doc_id && (v.id === doc_id || v.id === sec_doc_id)) {
                v["customer_type"] = `${customer_type}`;
                // console.log(doc_id,v.is_required,sec_doc_id,"rrrrrrrrrrrr")
                v.is_required = '1';
            } else {
                v["customer_type"] = "";
            }
            return v;
        });
    }

    render() {
        const { tipOpen, displayTootipIcon } = this.state;
        const { filter_doc_list, activeTab, leadDetails, document_list, masterdataucrf } = this.props;
        let { UploadedFiles, isLoading } = this.state;
        let customerDetails = leadDetails?.customer || {};
        let coborrowerDetails = leadDetails?.co_borrower || {};

        let filter_doc_child_list = [];
        filter_doc_list && filter_doc_list.forEach(data => {
            switch (data.id) {
                case CUSTOMER_PRIMARY_DOC_ID: {
                    data.child = this.updateChildDocList(data.child, customerDetails?.primary_doc_id, customerDetails?.customer_type || 0, customerDetails?.secondary_doc_id);
                    break;
                }
                // case CUSTOMER_SECONDARY_DOC_ID: {
                //     data.child = this.updateChildDocList(data.child, customerDetails?.secondary_doc_id, customerDetails?.customer_type);
                //     break;

                // }
                case COBORROWER_PRIMARY_DOC_ID: {
                    data.child = this.updateChildDocList(data.child, coborrowerDetails?.cb_primary_doc_id, coborrowerDetails?.cb_customer_type || 0, coborrowerDetails?.cb_secondary_doc_id);
                    break;

                }
                default:
                // case COBORROWER_SECONDARY_DOC_ID: {

                //     data.child = this.updateChildDocList(data.child, coborrowerDetails?.cb_primary_doc_id, coborrowerDetails?.cb_customer_type, coborrowerDetails?.cb_secondary_doc_id);
                //     // data.child = this.updateChildDocList(data.child, coborrowerDetails?.cb_secondary_doc_id, coborrowerDetails?.cb_customer_type);
                //     break;

                // }
            }
            filter_doc_child_list = [...filter_doc_child_list, ...data.child]
        })
        let filterFiles = UploadedFiles?.length && UploadedFiles?.filter(o1 => filter_doc_child_list.some(o2 => (o1.doc_id === o2.id || o1.doc_id === 0)));

        const datawithCount = filterFiles?.length ? [...filterFiles.reduce((mp, o) => {
            if (!mp.has(o.doc_id)) mp.set(o.doc_id, { ...o, count: 0 });
            mp.get(o.doc_id).count++;
            return mp;
        }, new Map()).values()] : []

        let temp = [];
        let currCustomerType = customerDetails?.customer_type || 0, iswaterBillUploaded = 0, iselectricBillUploaded = 0, isbarangybusinsspermitUploaded = 0, ismayorpermitUploaded = 0, isstipermitUploaded = 0, ispaySlipUploaded = 0, isBankStatementUploaded = 0,
            currCBCustomerType = coborrowerDetails?.cb_customer_type || 0,
            iscbwaterBillUploaded = 0, iscbelectricBillUploaded = 0, iscbbarangybusinsspermitUploaded = 0, iscbmayorpermitUploaded = 0, iscbstipermitUploaded = 0, iscbpaySlipUploaded = 0, iscbBankStatementUploaded = 0;


        const filterDocumentsByTabAndSection = (tabName, docSection) => {
            return document_list.filter((doc) => doc.doc_category === tabName && doc.doc_section === docSection);
        }

        const showDocs = (temp) => {
            let doc_list_arr = temp.map((doc, index) => {
                if (doc.id == SOFT_APPROVAL_PARENT_ID) {
                    let soft_approval_child = []
                    let selected_financier_data = leadDetails?.soft_approval_status?.filter(e => e.soft_approval_status == 1) || []
                    let selected_financier_ids = selected_financier_data?.map(e => e.financier_id) || []
                    if (selected_financier_ids?.length) {
                        for (const ob of selected_financier_ids) {
                            let data_exist = soft_approval_child.findIndex(e => e.financier_id == ob)
                            if (data_exist == -1) {
                                let temp_object = {
                                    id: doc.child[0].id,
                                    parent_id: doc.child[0].parent_id,
                                    min_upload: doc.child[0].min_upload || 1,
                                    max_upload: doc.child[0].max_upload || 5,
                                    financier_id: ob,
                                    name: "Soft Approval Proof",
                                    financier_name: ob ? masterdataucrf?.financier_list?.filter(e => e.id == ob)[0]?.financier_name || ob : "",
                                    count: doc?.child?.length ? UploadedFiles?.filter(e => e.doc_id == doc.child[0].id && e.financier_id == ob).length : 0
                                }
                                soft_approval_child.push(temp_object)
                            }
                        }
                    } else {
                        let temp_object = {
                            id: doc.child[0].id,
                            parent_id: doc.child[0].parent_id,
                            min_upload: doc.child[0].min_upload || 1,
                            max_upload: doc.child[0].max_upload || 5,
                            financier_id: 0,
                            financier_name: "",
                            name: "Soft Approval Proof",
                            count: 0
                        }
                        soft_approval_child.push(temp_object)
                    }
                    doc.child = soft_approval_child
                } else if (doc.id == LOAN_APPROVAL_PARENT_ID) {
                    let loan_approval_child = []
                    let selected_financier_data = leadDetails?.financier_loan_approval?.loan_details ? leadDetails?.financier_loan_approval?.loan_details?.filter(e => e?.additional_loan_info?.loan_approved == "yes") : []
                    let selected_financier_ids = selected_financier_data?.map(e => e.financier_id) || []
                    if (selected_financier_ids?.length) {
                        for (const ob of selected_financier_ids) {
                            let data_exist = loan_approval_child.findIndex(e => e.financier_id == ob)
                            if (data_exist == -1) {
                                let temp_object = {
                                    id: doc.child[0].id,
                                    parent_id: doc.child[0].parent_id,
                                    min_upload: doc.child[0].min_upload || 1,
                                    max_upload: doc.child[0].max_upload || 5,
                                    financier_id: ob,
                                    name: "Financier Loan Approval",
                                    financier_name: ob ? masterdataucrf?.financier_list?.filter(e => e.id == ob)[0]?.financier_name || ob : "",
                                    count: doc?.child?.length ? UploadedFiles?.filter(e => e.doc_id == doc.child[0].id && e.financier_id == ob).length : 0
                                }
                                loan_approval_child.push(temp_object)
                            }
                        }
                    } else {
                        let temp_object = {
                            id: doc.child[0].id,
                            parent_id: doc.child[0].parent_id,
                            min_upload: doc.child[0].min_upload || 1,
                            max_upload: doc.child[0].max_upload || 5,
                            financier_id: 0,
                            financier_name: "",
                            name: "Financier Loan Approval",
                            count: 0
                        }
                        loan_approval_child.push(temp_object)
                    }
                    doc.child = loan_approval_child
                } else {
                    doc.child = doc.child.map((ob) => ({
                        ...ob,
                        count: UploadedFiles.filter(e => e.doc_id == ob.id).length
                    }))
                }
                doc.count = doc.child.filter(data => data.doc_id == doc.id).map(v => v.count)[0] || 0;
                var childCountArray = doc.child.filter(val => val.count > 0);
                let docUploadStatus = ((doc.child.length === 0 && doc.count >= doc.min_upload) || (doc.child.length > 0 && childCountArray.length > 0)
                ) ? 'done' : '';
                let showParentRequired = (doc.child && (doc.child.length && (doc.child.some(item => item.is_required == "1") || false)));
                return (
                    <AccordionItem key={doc.id}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                {
                                    (docUploadStatus === 'done') ? (<span className="stepprogress"><i className="sprite ic-check"></i></span>) : null
                                }

                                {`${doc?.name} ${showParentRequired ? ' *' : ''}`}
                                {
                                    (doc.min_upload && displayTootipIcon) ? (
                                        <React.Fragment>
                                            <span onClick={() => this.toggleTip(doc.id)} title="" className="tooltio-ic"><i className="ic-info-icon1"></i>
                                            </span>
                                            <Tooltip
                                                html={(
                                                    <div ref={this.tipContentRef} className="controlled-example tooltip-main">
                                                        <div className="tooltip-data">

                                                            <span className="controlled-example_close-button" onClick={() => this.toggleTip(doc.id)}><i className="ic-clearclose"></i></span>
                                                            <p>
                                                                <span className='label'>Min Upload : {doc.min_upload}</span> &nbsp; | &nbsp;
                                                                <span className='label'>Max Upload : {doc.max_upload}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                                open={tipOpen == doc.id}
                                                tagName="span"
                                                position="bottom"
                                                trigger="click"
                                                interactive={true}
                                                animateFill={false}
                                                arrow={true}
                                                arrowSize="small"
                                                className="tootip-outer tooltip-doc-list"
                                                onToggle="true"
                                                forceDirection
                                            >
                                            </Tooltip>
                                        </React.Fragment>
                                    ) : null
                                }

                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="acco-doc-list">
                                <ul>
                                    {
                                        (doc.child.length) ?
                                            doc.child.map((doc, index) => {
                                                return (<li key={`${doc.id}${index}`} onClick={() => this.setFilter(doc.id, doc.parent_id, doc.financier_id)}>{`${doc?.name} ${doc.financier_id ? " | " + doc.financier_name : ""}`}
                                                    <span className="doc-count">{doc.count} /{doc.max_upload} </span>
                                                </li>
                                                )
                                            })
                                            : <li key={`${doc.id}${index}`} onClick={() => this.setFilter(doc.id, doc.parent_id, doc.financier_id)}>{`${doc?.name}`}
                                                <span className="doc-count">{doc.count} / {doc.max_upload}</span>
                                            </li>
                                    }
                                </ul>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                )
            })
            return doc_list_arr
        }

        let tabInfo = [
            { key: "customer-docs", title: "Customer Documents", tab: "first_submit", section: "customer_details" },
            { key: "co-borrower-docs", title: "Co-Borrower Documents", tab: "first_submit", section: "co_borrower_details" },
            { key: "other-docs", title: "Other Documents", tab: "first_submit", section: "other_document" },
            { key: "credit-investigation-docs", title: "Credit Investigation Documents", tab: "disbursal", section: "credit_investigation" },
            { key: "outbound-payment-docs", title: "Outbound Payment Proof", tab: "post_disbursal", section: "outbound_payment" },
            { key: "inbound-payment-docs", title: "Inbound Payment Proof", tab: "post_disbursal", section: "inbound_payment" },
            { key: "transfer-confirmation", title: "Transfer Confirmation Proof", tab: "post_disbursal", section: "transfer_confirmation" },
        ];
        const renderedTabs = (
            <div className="col-md-12">
                {document_list?.length > 0 && tabInfo
                    .filter((v) => activeTab === v.tab)
                    .map((tab, index) => {
                        const documents = filterDocumentsByTabAndSection(tab.tab, tab.section);
                        return (
                            <Accordion allowMultipleExpanded="true" allowZeroExpanded style={{ paddingLeft: '0px' }}>
                                <AccordionItem key={`${activeTab}_${filter_doc_list.length}_${index}`} className='acc-item-financer'>
                                    <AccordionItemHeading className='acc-heading-financer'>
                                        <AccordionItemButton>
                                            <label className="">
                                                <h3>{tab.title}</h3>
                                            </label>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="acc-item-panel">
                                        <div className="sub-list">
                                            <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                                                {showDocs(documents)}
                                            </Accordion>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        );
                    })}
            </div>
        );



        return (
            <div className="upload-document-list-accordain" key={`${activeTab}_${filter_doc_list.length}`}>
                <div className="doc-list-heading">
                    <h3>List of documents</h3>
                </div>
                <br></br>
                {
                    (isLoading) ? (<Loader />) : null
                }
                {renderedTabs}
            </div>
        )
    }
}

const mapStateToProps = ({ lead, common, user }) => {
    return {
        authUser: user.authUser,
        stateleadDocs: lead.ucrf_lead_docs
    };
}

export default withTranslation('loan_details')(connect(mapStateToProps, null)(UploadDocumentList));