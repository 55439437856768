import React, { Component } from 'react';
import {Tooltip} from 'react-tippy';
import { Tab, Tabs } from 'react-bootstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Loader from '../../elements/Loader';
import { CUSTOMER_PRIMARY_DOC_ID, COBORROWER_PRIMARY_DOC_ID } from '../../../config/constants';
import LeadHelper from "../LeadHelper";
class UploadDocumentList extends Component {
    constructor(props) {
        super(props);
        this.tipContentRef = React.createRef();
        this.buttonRef = React.createRef();
        this.state = {
            tipOpen: 0,
            UploadedFiles: this.props.UploadedFiles || [],
            isLoading: false,
            displayTootipIcon: false
        };
        this.toggleTip = this.toggleTip.bind(this);
        this.bodyClick = this.bodyClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.bodyClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.bodyClick);
    }

    toggleTip(id = 0) {
        let { tipOpen } = this.state;
        if (id) this.setState({ tipOpen: (tipOpen === id) ? 0 : id });
    }
    bodyClick(e) {
        this.setState({ tipOpen: 0 });
    }

    setFilter = (doc_id, parent_id) => {
        this.props.setFilter({ doc_id: doc_id, file_id: 0, parent_id: parent_id });
    }

    updateChildDocList = (doc_list, doc_id, customer_type,sec_doc_id) => {
        return doc_list?.map(v => {
            if (doc_id && (v.id === doc_id || v.id === sec_doc_id)) {
                v["customer_type"] = `${customer_type}`;
                // console.log(doc_id,v.is_required,sec_doc_id,"rrrrrrrrrrrr")
                v.is_required='1';
            } else {
                v["customer_type"] = "";
            }
            return v;
        });
    }
    
    render() {
        const { tipOpen, displayTootipIcon } = this.state;
        const { filter_doc_list, activeTab, leadDetails } = this.props;
        let { UploadedFiles, isLoading } = this.state;
        let customerDetails = leadDetails?.customer || {};
        let coborrowerDetails = leadDetails?.co_borrower || {};

        // console.log(filter_doc_list,"rrrrrrrrrrrrrrcccccccccc")
        let filter_doc_child_list = [];
        filter_doc_list && filter_doc_list.forEach(data => {
            switch(data.id) {
                case CUSTOMER_PRIMARY_DOC_ID: {
                    data.child = this.updateChildDocList(data.child, customerDetails?.primary_doc_id, customerDetails?.customer_type || 0,customerDetails?.secondary_doc_id);
                    break;
                }
                // case CUSTOMER_SECONDARY_DOC_ID: {
                //     data.child = this.updateChildDocList(data.child, customerDetails?.secondary_doc_id, customerDetails?.customer_type);
                //     break;

                // }
                case COBORROWER_PRIMARY_DOC_ID: {
                    data.child = this.updateChildDocList(data.child, coborrowerDetails?.cb_primary_doc_id, coborrowerDetails?.cb_customer_type || 0, coborrowerDetails?.cb_secondary_doc_id);
                    break;

                }
                default:
                // case COBORROWER_SECONDARY_DOC_ID: {

                //     data.child = this.updateChildDocList(data.child, coborrowerDetails?.cb_primary_doc_id, coborrowerDetails?.cb_customer_type, coborrowerDetails?.cb_secondary_doc_id);
                //     // data.child = this.updateChildDocList(data.child, coborrowerDetails?.cb_secondary_doc_id, coborrowerDetails?.cb_customer_type);
                //     break;

                // }
            }
            filter_doc_child_list = [...filter_doc_child_list, ...data.child]
        })
        let filterFiles = UploadedFiles.filter(o1 => filter_doc_child_list.some(o2 => (o1.doc_id === o2.id || o1.doc_id === 0)));
        
        const datawithCount = [...filterFiles.reduce((mp, o) => {
            if (!mp.has(o.doc_id)) mp.set(o.doc_id, { ...o, count: 0 });
            mp.get(o.doc_id).count++;
            return mp;
        }, new Map()).values()];
        // console.log(datawithCount,"datawithCount")
        // check for if doc tagged for Tax id Card 

        let temp = [];
        let currCustomerType = customerDetails?.customer_type || 0,iswaterBillUploaded=0,iselectricBillUploaded=0,isbarangybusinsspermitUploaded=0,ismayorpermitUploaded=0,isstipermitUploaded=0,ispaySlipUploaded=0,isBankStatementUploaded=0,
        currCBCustomerType = coborrowerDetails?.cb_customer_type || 0,
        iscbwaterBillUploaded=0,iscbelectricBillUploaded=0,iscbbarangybusinsspermitUploaded=0,iscbmayorpermitUploaded=0,iscbstipermitUploaded=0,iscbpaySlipUploaded=0,iscbBankStatementUploaded=0;
        filter_doc_list.forEach((element, i) => {
            if (element.parent_id === 0) {
                element.count = datawithCount.filter(data => data.doc_id === element.id).map(v => v.count)[0] || 0;
                iswaterBillUploaded = datawithCount.filter(data => data.doc_id === 33).map(v => v.count)[0] || 0;
                iselectricBillUploaded = datawithCount.filter(data => data.doc_id === 34).map(v => v.count)[0] || 0;

                isstipermitUploaded = datawithCount.filter(data => data.doc_id === 39).map(v => v.count)[0] || 0;
                ismayorpermitUploaded = datawithCount.filter(data => data.doc_id === 40).map(v => v.count)[0] || 0;
                isbarangybusinsspermitUploaded = datawithCount.filter(data => data.doc_id === 41).map(v => v.count)[0] || 0;


                ispaySlipUploaded = datawithCount.filter(data => data.doc_id === 37).map(v => v.count)[0] || 0;
                isBankStatementUploaded = datawithCount.filter(data => data.doc_id === 38).map(v => v.count)[0] || 0;

                iscbwaterBillUploaded = datawithCount.filter(data => data.doc_id === 75).map(v => v.count)[0] || 0;
                iscbelectricBillUploaded = datawithCount.filter(data => data.doc_id === 76).map(v => v.count)[0] || 0;

                iscbstipermitUploaded = datawithCount.filter(data => data.doc_id === 81).map(v => v.count)[0] || 0;
                iscbmayorpermitUploaded = datawithCount.filter(data => data.doc_id === 82).map(v => v.count)[0] || 0;
                iscbbarangybusinsspermitUploaded = datawithCount.filter(data => data.doc_id === 83).map(v => v.count)[0] || 0;


                iscbpaySlipUploaded = datawithCount.filter(data => data.doc_id === 79).map(v => v.count)[0] || 0;
                iscbBankStatementUploaded = datawithCount.filter(data => data.doc_id === 80).map(v => v.count)[0] || 0;

                element["child"] = filter_doc_child_list.filter(data => data.parent_id === element.id).map(v=>{
                    let docObj=Object.assign({},v);
                    // console.log(docObj)
                    let is_required;
                    if(docObj.doc_section === "customer_details"){
                        is_required = v.customer_type && v.customer_type.includes(currCustomerType) ? "1" : '0';
                        // console.log(is_required)
                        if(is_required==='1'){
    
                            if(v.id===33 && iselectricBillUploaded && !iswaterBillUploaded) is_required='0';
                            if(v.id===34){
                                is_required='1';
                                if(iswaterBillUploaded && !iselectricBillUploaded) is_required='0'
                            }
    
                            if(v.id===39 && (ismayorpermitUploaded || isbarangybusinsspermitUploaded)) is_required='0';
                            if(v.id===40 && (isstipermitUploaded || isbarangybusinsspermitUploaded)) is_required='0';
                            if(v.id===41){
                                is_required='1'; 
                                if(ismayorpermitUploaded || isstipermitUploaded) is_required='0'; 
                            }
                            
    
                            if(v.id===37 && isBankStatementUploaded && !ispaySlipUploaded) is_required='0';  
                            if(v.id===38){
                                is_required='1';
                                if(ispaySlipUploaded && !isBankStatementUploaded)  is_required='0';
                            }
                        }
                    }
                    else if(docObj.doc_section === "co_borrower_details"){
                        is_required = v.customer_type && v.customer_type.includes(currCBCustomerType) ? "1" : '0';
                        if(is_required==='1'){
    
                            if(v.id===75 && iscbelectricBillUploaded && !iscbwaterBillUploaded) is_required='0';
                            if(v.id===76){
                                is_required='1';
                                if(iscbwaterBillUploaded && !iscbelectricBillUploaded) is_required='0'
                            }
    
                            if(v.id===81 && (iscbmayorpermitUploaded || iscbbarangybusinsspermitUploaded)) is_required='0';
                            if(v.id===82 && (iscbstipermitUploaded || iscbbarangybusinsspermitUploaded)) is_required='0';
                            if(v.id===83){
                                is_required='1'; 
                                if(iscbmayorpermitUploaded || iscbstipermitUploaded) is_required='0'; 
                            }
                            
    
                            if(v.id===79 && iscbBankStatementUploaded && !iscbpaySlipUploaded) is_required='0';  
                            if(v.id===80){
                                is_required='1';
                                if(iscbpaySlipUploaded && !iscbBankStatementUploaded)  is_required='0';
                            }
                        }
                    }
                    let uploaddocsCount = datawithCount.filter(data => data.doc_id === v.id).map(v => v.count)[0] || 0;
                    docObj.is_required = is_required;
                    docObj.count = uploaddocsCount
                    return docObj;
                }) || [];
                temp.push(element);
            }
        });

        const filterDocumentsByTabAndSection = (temp, tabName, docSection) => {
            if(docSection === "soft_approval_recieved"){
                const updatedDocs = LeadHelper.getFinancierSoftApprovalDoc(temp, tabName, docSection, filterFiles, leadDetails?.financier);
                return updatedDocs;
            }
            return temp.filter((doc) => doc.tab_name === tabName && doc.doc_section === docSection);
        }

        const showDocs=(temp)=>{
            // console.log(temp,"temp")
            let doc_list_arr = temp.map((doc, index) => {
                var childCountArray = doc.child.filter(val => val.count > 0);
                var childRequiredCount = doc.child.filter(val => (val.count >= val.min_upload && val.is_required === '1') || (val.count > 0 && val.count >= val.min_upload));
                var childCountRequired = (doc.child.length) ? doc.child.filter(v => (v.is_required === '1') || (v.count > 0)) : [];
                let docUploadStatus = ((doc.child.length === 0 && doc.count >= doc.min_upload) || (doc.child.length > 0 && childRequiredCount.length === childCountRequired.length && childCountArray.length > 0)
                ) ? 'done' : '';
                let showParentRequired = (doc.child && (doc.child.length && (doc.child.some(item => item.is_required === "1") || false)));
                return (
                    <AccordionItem key={doc.id}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                {
                                    (docUploadStatus === 'done') ? (<span className="stepprogress"><i className="sprite ic-check"></i></span>) : null
                                }

                                {`${doc.doclang && doc.doclang.name} ${showParentRequired ? ' *' : ''}`}
                                {
                                    (doc.min_upload && displayTootipIcon) ? (
                                        <React.Fragment>
                                            <span onClick={() => this.toggleTip(doc.id)} title="" className="tooltio-ic"><i className="ic-info-icon1"></i>
                                            </span>
                                            <Tooltip
                                                html={(
                                                    <div ref={this.tipContentRef} className="controlled-example tooltip-main">
                                                        <div className="tooltip-data">

                                                            <span className="controlled-example_close-button" onClick={() => this.toggleTip(doc.id)}><i className="ic-clearclose"></i></span>
                                                            <p>
                                                                <span className='label'>Min Upload : {doc.min_upload}</span> &nbsp; | &nbsp;
                                                                <span className='label'>Max Upload : {doc.max_upload}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                                open={tipOpen === doc.id}
                                                tagName="span"
                                                position="bottom"
                                                trigger="click"
                                                interactive={true}
                                                animateFill={false}
                                                arrow={true}
                                                arrowSize="small"
                                                className="tootip-outer tooltip-doc-list"
                                                onToggle="true"
                                                forceDirection
                                            >
                                            </Tooltip>
                                        </React.Fragment>
                                    ) : null
                                }

                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="acco-doc-list">
                                <ul>
                                    {
                                        (doc.child.length) ?
                                            doc.child.map((doc, index) => {
                                                return (<li key={`${doc.id}${index}`} onClick={() => this.setFilter(doc.id, doc.parent_id)}>{`${doc.doclang.name} ${doc.financier_id ? " | "+doc.financier_name : ""} ${(doc.is_required === '1') ? '*' : ''}`}
                                                    <span className="doc-count">{doc.count} /{doc.max_upload} </span>
                                                </li>
                                                )
                                            })
                                            : <li key={`${doc.id}${index}`} onClick={() => this.setFilter(doc.id, doc.parent_id)}>{`${doc.doclang.name} ${(doc.is_required === '1') ? '*' : ''}`}
                                                <span className="doc-count">{doc.count} / {doc.max_upload}</span>
                                            </li>
                                    }
                                </ul>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                )
            })
            return doc_list_arr
        }

        let tabInfo = [
            { key: "customer-docs", title: "Customer Documents", tab: "first_submit", section: "customer_details" },
            { key: "co-borrower-docs", title: "Co-Borrower Documents", tab: "first_submit", section: "co_borrower_details" },
            { key: "soft-approval-docs", title: "Soft Approval Documents", tab: "pre-disbursal", section: "soft_approval_recieved" },
            { key: "credit-investigation-docs", title: "Credit Investigation Documents", tab: "pre-disbursal", section: "credit_investigation" },
            { key: "credit-document-recieved", title: "Credit Document Recieved", tab: "pre-disbursal", section: "credit_document_received" },
            { key: "contract-signed-documents", title: "Contract Signed Documents", tab: "pre-disbursal", section: "contact_signed_doc" },
            { key: "car-handover-pics", title: "Car Handover Pics", tab: "car_transfer", section: "car_handover_pics" },
            { key: "orcr-recieved-from-dealer", title: "Orcr Recieved From Dealer", tab: "car_transfer", section: "orcr_received_from_dealer" },
            { key: "orcr-sent-for-title-transfer-pics", title: "ORCR Sent for Title Transfer", tab: "car_transfer", section: "orcr_sent_for_title_transfer_pics" },
            { key: "orcr-recieved-after-title-transfer-pics", title: "ORCR Received After Title Transfer", tab: "car_transfer", section: "orcr_received_after_title_transfer_pics" },
            { key: "orcr-verified-by-financier", title: "Orcr Verified By Financier", tab: "car_transfer", section: "orcr_verified_by_financier" },
            { key: "orcr-recieved-from-dealer", title: "Paid To Dealer", tab: "post_disbursal", section: "paid_to_dealer" },
            { key: "payment-recieved-from-financier", title: "Payment Recieved From Financier", tab: "post_disbursal", section: "payment_received_from_financier" },
            { key: "transfer-confirmation", title: "Transfer Confirmation", tab: "post_disbursal", section: "transfer_confirmation" },
        ];
        // console.log(tabInfo,"tabInfo")
        const renderedTabs = (
            <div className="col-md-12">
                {/* <div className="row"> */}
                    {tabInfo
                        .filter((v) => activeTab === v.tab)
                        .map((tab, index) => {
                            const documents = filterDocumentsByTabAndSection(temp, tab.tab, tab.section);
                            // console.log(documents,"documents")
                            return (
                                <Accordion allowMultipleExpanded="true" allowZeroExpanded style={{paddingLeft:'0px'}}>
                                        <AccordionItem key={`${activeTab}_${filter_doc_list.length}_${index}`} className='acc-item-financer'>
                                            <AccordionItemHeading className='acc-heading-financer'>
                                            <AccordionItemButton>
                                                <label className="">
                                                    <h3>{tab.title}</h3>
                                                </label>
                                            </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel className="acc-item-panel">
                                            <div className="sub-list">
                                                <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                                                    {showDocs(documents)}
                                                </Accordion>
                                            </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                </Accordion>
                            );
                    })}
                {/* </div> */}
            </div>
            // <Tabs
            //     className='doc-uplaod-tabs m-lg-t'
            //     id="document-tabs"
            // >
            //     {tabInfo
            //         .filter((v) => activeTab === v.tab)
            //         .map((tab) => {
            //             const documents = filterDocumentsByTabAndSection(temp, tab.tab, tab.section);
            //             // console.log(documents,"documents")
            //             return (
            //                 <Tab eventKey={tab.key} title={tab.title} key={tab.key}>
            //                     <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
            //                         {showDocs(documents)}
            //                     </Accordion>
            //                 </Tab>
            //             );
            //         })}
            // </Tabs>
        );
          


        return (
            <div className="upload-document-list-accordain" key={`${activeTab}_${filter_doc_list.length}`}>
                <div className="doc-list-heading">
                    <h3>List of documents</h3>
                </div>
                <br></br>
                {
                    (isLoading) ? (<Loader />) : null
                }
                {renderedTabs}
            </div>
        )
    }
}

const mapStateToProps = ({ lead, common, user }) => {
    return {
        authUser: user.authUser
    };
}

export default withTranslation('loan_details')(connect(mapStateToProps, null)(UploadDocumentList));