import React, { useEffect, useState, memo } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Nav } from 'react-bootstrap';
import ListGridView from './DocumentViewGallery';
import { CONTRACT_SIGNED, LOST_STATUS, SENT_TO_FINANCIER } from '../../../config/refinance/constant';
import { executeGraphQLQuery } from '../../../services/refinance/executeGraphQLQuery';
import { WEB_DOC_CONFIG_LIST_REFINANCE } from '../../../services/refinance/leads.gql';
import { useApolloClient } from '@apollo/client';

const DocumentGallery = (props) => {
    const client = useApolloClient();
    const [docCategoryList, setDocCategoryList] = useState(props.documentCategoryList);
    const [documentList, setDocumentList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [selectedTab, setSelectedTab] = useState('first_submit');
    const [seletedTabDocs, setSelectedTabDocs] = useState([]);
    const [filterCategoryChildList, setFilterCategoryChildList] = useState([])
    const defaultCategory = { label: "Primary Govt ID", value: 1 };
    const defaultSubCategory = { label: "Driver's License", value: 1 };
    let documentCategoryList = useSelector((state) => state?.lead?.doc_config);
    const { leadDetail, leadDocs, master_data_ucrf, doc_configs } = useSelector(({ lead, user, masterdataucrf }) => ({
        leadDocs: lead?.ucrf_lead_docs,
        userInfo: user.user_information || {},
        leadDetail: lead && lead.ucrfLeadDetail,
        master_data_ucrf: masterdataucrf?.master_data_ucrf || {},
        doc_configs: lead?.doc_config || []
        // leadStatusHistory: (lead.leadDetail && (lead.leadDetail.lead_status_history || []))
    }));
    const user_information = localStorage.getItem('user_information');
    let authUser = (user_information && JSON.parse(user_information));
    // let subStatusMarkedTillnow = (leadStatusHistory && leadStatusHistory.length) ? leadStatusHistory.map(v=>v.sub_status_id) : [];

    const [docGallery, setDocGallery] = useState({
        selectedCategory: { ...defaultCategory },
        selectedSubCategory: { ...defaultSubCategory },
        filterFiles: [],
        filterCategory: docCategoryList
    });

    const getDocCategoryList = async (lead_id) => {
        try {
            const result =  await executeGraphQLQuery(WEB_DOC_CONFIG_LIST_REFINANCE(lead_id), client);
            return result?.data?.webDocumentConfig || []
            
        } catch (error) {
        }
    }

    const setDocumentCategory = async (documentCategoryList, leadDocs) =>{
        let docCategoryList = documentCategoryList || [];
        if(leadDetail.lead_id){
            docCategoryList = await getDocCategoryList(leadDetail.lead_id);
        }
        setDocumentList(leadDocs);
        setDefaultImageCategory();
        let category = docCategoryList.filter(f => f.doc_category == selectedTab && f.id !== 111).map(e => ({ label: e?.name, value: e.id }))
        let sub_category = docCategoryList.filter(f => f.id == defaultCategory.value)?.map(v => v.child)[0] || []
        let seletedTabDocIds = sub_category.filter(f => f.doc_category == selectedTab).map(e => e.id);
        sub_category = sub_category && sub_category.length && sub_category.map(e => ({ label: e?.name, value: e.id }));
        let filterCategoryChildLists = [];
        docCategoryList && docCategoryList.filter(f => f.doc_category == selectedTab).forEach(data => {
            filterCategoryChildLists = [...filterCategoryChildLists, ...data.child]
        });
        setFilterCategoryChildList(filterCategoryChildLists);
        seletedTabDocIds = filterCategoryChildLists.map(e => e.id);
        if (leadDocs?.length) {
            let selectedTabDocs = leadDocs.reduce((res, data) => {
                if (seletedTabDocIds.indexOf(data.doc_id) > -1) res.push(data);
                return res;
            }, []);
            setSelectedTabDocs(selectedTabDocs);
        }
        setDocCategoryList(docCategoryList);
        setCategoryList(category);
        setSubCategoryList(sub_category);
    }

    useEffect(() => {
        setDocumentCategory(documentCategoryList,leadDocs)
    }, [props, leadDocs?.length]);

    const setDefaultImageCategory = () => {
        /*  Set default image on load */
        if (leadDocs?.length) {
            let filtredFiles = leadDocs.reduce((res, data) => {
                if (data.doc_id == defaultSubCategory.value) res.push(data);
                return res;
            }, []);
            setDocGallery({ ...docGallery, filterFiles: filtredFiles, selectedCategory: { ...defaultCategory }, selectedSubCategory: { ...defaultSubCategory } });
        }
    };

    const handleSelectTab = async (eventKey) => {
        setSelectedTab(eventKey);
        let docCategoryList = documentCategoryList || [];
        if(leadDetail.lead_id){
            docCategoryList = await getDocCategoryList(leadDetail.lead_id);
        }
        let category = docCategoryList.filter(f => f.doc_category == eventKey && f.id !== 111).map(e => ({ label: e?.name, value: e.id }));
        setCategoryList(category);
        setSubCategoryList([]);
        let filterCategoryChildLists = [];
        docCategoryList && docCategoryList.filter(f => f.doc_category == eventKey).forEach(data => {
            filterCategoryChildLists = [...filterCategoryChildLists, ...data.child]
        });
        setFilterCategoryChildList(filterCategoryChildLists)

        let seletedTabDocIds = filterCategoryChildLists.map(e => e.id);
        let selectedTabDocs = leadDocs?.reduce((res, data) => {
            if (seletedTabDocIds.indexOf(data.doc_id) > -1) res.push(data);
            return res;
        }, []) || [];
        setDocGallery({ ...docGallery, selectedCategory: {}, selectedSubCategory: {} });
        setSelectedTabDocs(selectedTabDocs);
    }
    let markAsFreezed = leadDetail?.customer_timeline_status_ids?.length && leadDetail.customer_timeline_status_ids.findIndex(e => e == SENT_TO_FINANCIER || e == CONTRACT_SIGNED) > -1 ? true : false
    let leadLost = leadDetail?.basic_details?.refinance_status ? leadDetail?.basic_details?.refinance_status == LOST_STATUS.toString() : false;
    return (
        <div className="doument-gallery-tabs" key={selectedTab}>
            <Tab.Container id="left-tabs-example" defaultActiveKey={selectedTab} onSelect={handleSelectTab}>
                <Nav variant="pills" className="flex-column">
                    <div className="tab-list">
                        <Nav.Item>
                            <Nav.Link active={selectedTab == 'first_submit'} eventKey="first_submit" disabled={false}>First Submit</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link active={selectedTab == 'disbursal'} eventKey="disbursal" disabled={false}>Pre Disbursal</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link active={selectedTab == 'post_disbursal'} eventKey="post_disbursal" disabled={false}>Post Disbursal</Nav.Link>
                        </Nav.Item>
                    </div>
                </Nav>

                <Tab.Content>
                    <Tab.Pane active={selectedTab} activeTab={selectedTab} eventKey={selectedTab}>
                        <ListGridView
                            docGallery={docGallery}
                            categoryList={categoryList}
                            subCategoryList={subCategoryList}
                            docCategoryList={docCategoryList}
                            filterCategoryChildList={filterCategoryChildList}
                            documentList={documentList}
                            seletedTabDocs={seletedTabDocs}
                            selectedTab={selectedTab}
                            authUser={authUser}
                            setDefaultImageCategory={setDefaultImageCategory}
                            setSubCategoryList={setSubCategoryList}
                            setDocGallery={setDocGallery}
                            key={selectedTab}
                            leadDetail={leadDetail}
                            markAsFreezed={markAsFreezed}
                            leadLost={leadLost}
                            master_data_ucrf = {master_data_ucrf}
                        />
                    </Tab.Pane>
                </Tab.Content>

            </Tab.Container>
        </div>
    )
}

export default memo(DocumentGallery);